import React from "react"
import Style from "./media.module.scss"

import ReactPlayer from "react-player"

/**
 * MediaMisc (Default): Component for miscellaneous media,
 * currently only SoundCloud embeds
 */
const MediaMisc = props => {
  return (
    <div className={Style.mediaMiscPane}>
      <h2>SOUNDCLOUD</h2>
      <hr />
      <br />
      {props.mediaData.map((track, id) => {
        return (
          <div
            style={{
              position: "relative",
              width: "100%",
            }}
            key={id}
          >
            <ReactPlayer
              url={track.node.soundCloudUrl}
              playing={false}
              controls={true}
              width="auto"
              height="auto"
            />
            <br />
          </div>
        )
      })}
    </div>
  )
}

export default MediaMisc

/* =================================================== */
