import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import MediaVideos from "../components/media/media-videos.js"
import MediaMisc from "../components/media/media-misc.js"

const Media = () => {
  // Array of all the data for every Wet Ink event
  const ql_media = useStaticQuery(graphql`
    query {
      # All Vimeo video embeds
      allContentfulVimeoVideo {
        edges {
          node {
            title
            composer
            location
            yearOfComposition
            datePerformance(formatString: "LL")
            vimeoUrl
          }
        }
      }

      # All SoundCloud audio embeds
      allContentfulSoundCloudAudio {
        edges {
          node {
            title
            soundCloudUrl
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Support" />
      <MediaVideos mediaData={ql_media.allContentfulVimeoVideo.edges} />
      <MediaMisc mediaData={ql_media.allContentfulSoundCloudAudio.edges} />
    </Layout>
  )
}

export default Media
