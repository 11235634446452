import React from "react"
import { useMediaQuery } from "react-responsive"
import Style from "./media.module.scss"

import ReactPlayer from "react-player"

/**
 * MediaVideos (Default): Component for a collection
 * of Wet Ink's Vimeo videos
 */
const MediaVideos = props => {
    const isMobile = useMediaQuery({ query: "(max-width: 1024px)" })
    return (
        <div className={Style.mediaVideosPane}>
            <h2>VIDEOS</h2>
            <hr />
            <div className={Style.mediaGrid}>
                {props.mediaData.map((video, id) => {
                    let videoNumber = getVimeoVideoNumber(video.node.vimeoUrl)

                    return (
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                overflow: "none",
                            }}
                            key={id}
                        >
                            <ReactPlayer
                                url={`https://player.vimeo.com/video/${videoNumber}`}
                                playing={false}
                                controls={true}
                                width="auto"
                                height={isMobile ? "50vw" : "14vw"}
                            />
                            <h4 style={{ textAlign: "left" }}>
                                <i>{video.node.title}</i>: {video.node.composer} (
                                {video.node.yearOfComposition})<br />
                                {video.node.location}
                                <br />
                                {video.node.datePerformance}
                            </h4>
                            <br />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default MediaVideos

/* =================================================== */

/**
 * getVimeoVideoNumber(): Extracts video number from
 * Vimeo URL
 */

function getVimeoVideoNumber(url) {
    var vimeoVideoNumber = (url.match(/\d+\.\d+|\d+\b|\d+(?=\w)/g) || [])
        .map(function (v) {
            return +v
        })
        .pop()

    return vimeoVideoNumber
}
